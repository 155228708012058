import { listenToStateChangeIO, start, join, openWebSocket, updatePlayerPositionApi, waitForStartGame, waitForPlayers, waitOnDisconnectPlayers } from '../api/socket-api';
import { runGame, setStartPosition, updateAllSprites, setPhaser } from '../reducers/gameSlice';
import { startGameRest } from '../api/rest-api';
import store from "../store";


export const setPhasergame = (game) => {
        store.dispatch(setPhaser(game));

}
export const joinGame = (callback) => {

    join(payload => {
        // {roomId: roomId, 
        store.dispatch(setStartPosition(payload));
        callback();
        startWaitForStartGame();
    }
    );

}

export const listenToStateChange = (callback) => {
    listenToStateChangeIO(payload => {
        // {roomId: roomId, 

        callback(payload);
        store.dispatch(setStartPosition(payload));
    }


    )
}

export const startGame = () => {

    // start();
    startGameRest();



}

export const startWaitForStartGame = () => {

    waitForStartGame(payload => {
        console.log(payload);
        console.log("startGameAck dispatch");
        store.dispatch(runGame(payload));
    });

}


export const waitForOtherPlayers = (callback) => {
    waitForPlayers(payload => {
        callback(payload);
        console.log(payload);
        //store.dispatch(updateAllSprites(payload));

    });

}

export const waitOnDisconnect = (callback) => {
    waitOnDisconnectPlayers(payload => {

        console.log(payload);
        callback(payload);
        // store.dispatch(updateAllSprites(payload));

    });

}



export const openSocket = () => {
    openWebSocket();
}

export const updatePlayerPosition = msg => {
    updatePlayerPositionApi(msg, payload => {
        // {roomId: roomId, 

        store.dispatch(updateAllSprites(payload))
    }
    );

}

