import logo from '../img/logo.png';
import title from '../img/title.png';
import '../css/Won.css';
import { Link } from 'react-router-dom'

function Won() {
  return (
    
    <div className="Won">
   
      <header className="Won-header">
       <h1>Glückwunsch, du hast gewonnen!</h1>

       <Link to="/game">Nochmal</Link>
       
       </header>
    </div>
  );
}

export default Won;