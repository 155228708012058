import logo from './img/logo.png';
import title from './img/title.png';
import Start from './js/Start';
import Game from './js/Game';
import Lobby from './js/Lobby';
import Won from './js/Won';
import Lost from './js/Lost';
import { Admin } from './js/Admin';
import './css/App.css';
import {
  BrowserRouter as Router,
  Routes, 
  Route,
  Link
} from "react-router-dom";

const Home = () => { return <h1>Home Content</h1>};

function App() {
  return (
    
   
      <Router>
      <Routes>
        <Route exact path="/" element={<Start/>}/>
        <Route exact path="/lobby" element={<Lobby/>}/>
        <Route exact path="/game" element={<Game/>}/>
        <Route exact path="/lost" element={<Lost/>}/>
        <Route exact path="/won" element={<Won/>}/>
        <Route exact path="/admin" element={<Admin/>}/>
      </Routes>
    </Router>
      
       
  );
}

export default App;
