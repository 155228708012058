import socketIO from "socket.io-client";


const PORT = 5000;
var socketString = "";
if (process.env.NODE_ENV === "production") {
    socketString = 'https://www.cakewar.de';
}
if (process.env.NODE_ENV === "development") {
    socketString = `http://localhost:${PORT}`;

}
var socket;
export const openWebSocket = () => {
    socket = socketIO(socketString);
}
export const join = (callback) => {
    socket.emit("join", (response) => {
        console.log("Game joined");
        console.log(response);
        callback(response);


    });

}

export const listenToStateChangeIO = (callback) => {
    socket.on('stateChange', response => {
        callback(response);
        console.log("State change");
        console.log(response);
    });
}

export const waitForPlayers = (callback) => {
    socket.on('newPlayer', response => {
        callback(response);
        console.log("New Sprite");
        console.log(response);
    });
}

export const waitOnDisconnectPlayers = (callback) => {
    socket.on('destroySprite', response => {
        callback(response);
        console.log("Destroy Sprite");
        console.log(response);
    });
}



export const waitForStartGame = (callback) => {

    socket.on('startGameAck', response => {
        callback(response);
        console.log(response);
        socket.emit("startUpdating", () => {
            console.log("Updating startet");


        });

    });
}


export const start = () => {

    socket.emit("startGame", () => {
        console.log("Game startet");


    });
}

export const updatePlayerPositionApi = (msg, callback) => {
    socket.emit("playerUpdate", msg, (response) => {
        // console.log(response);
        callback(response);

    });
}
