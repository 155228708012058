import { createSlice } from '@reduxjs/toolkit'
import { RUN_GAME, WAIT_GAME, WON_GAME, LOST_GAME } from '../js/types';

export const gameSlice = createSlice({
    name: 'game',
    initialState: {
        phaser:null,
        running: false,
        lifePoints: 200,
        id: 0,
        speed: 0,
        rotation: 0,
        posX: 0,
        posY: 0,
        sprites: [],
        state: WAIT_GAME
        
    },
    reducers: {

        setStartPosition: (state, action) => {
            state.sprites = action.payload.sprites;
            state.id = action.payload.id;

        },
        runGame: (state, action) => {

            state.running = action.payload.running;
            state.sprites = action.payload.sprites;
            if (action.payload.sprites)

                state.lifePoints =  action.payload.sprites.find(el => el.id === state.id)?action.payload.sprites.find(el => el.id === state.id)?.lifePoints:0;

        },

        updateAllSprites: (state, action) => {

            state.sprites = action.payload.sprites;
            if (action.payload.sprites)

            state.lifePoints =  action.payload.sprites.find(el => el.id === state.id)?action.payload.sprites.find(el => el.id === state.id)?.lifePoints:0;


        },
        setPhaser: (state, game) => {
            state.phaser=game;
        }

    }

})



// Action creators are generated for each case reducer function
export const { setStartPosition, runGame, updateAllSprites, newPlayer, setPhaser } = gameSlice.actions

export default gameSlice.reducer