
import '../css/Lobby.css';
import { Link } from 'react-router-dom'

function Lobby() {
  return (
    
    <div className="Lobby">
   
      <header className="Lobby-header">
       <h1>Lobby</h1>

       <Link to="/game">Game</Link>
    
       </header>
    </div>
  );
}

export default Lobby;