import axios from "axios";
const PORT = 5000;
if (process.env.NODE_ENV === "production") {
axios.defaults.baseURL = 'https://www.cakewar.de';}
if (process.env.NODE_ENV === "development") {
    axios.defaults.baseURL = `http://localhost:${PORT}`;

}


// Register User
export const startGameRest = () => {

    axios
        .post("api/start"/*,
            {
                proxy: {
                    protocol: 'http',
                    host: 'localhost',
                    port: PORT,

                }
            }*/)
        .then(console.log("Game started!"))
        .catch(err => {
            console.log(err);

        });
};
