
import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import '../css/Lobby.css';
import Phaser from 'phaser';


import { setPhasergame, listenToStateChange, joinGame, openSocket, startGame, updatePlayerPosition, waitForOtherPlayers, waitOnDisconnect } from '../actions/gameActions'
import store from '../store';

import { CAKE_SHIP, SMARTIE_SHOT, WON_GAME, LOST_GAME, MEDI_KIT } from './types';


const HEIGHT = 600;
const WIDTH = 1125;





export const Game = () => {





  useEffect(() => {

    let config = {
      type: Phaser.AUTO,
      parent: 'phaser-example',
      width: WIDTH,
      height: HEIGHT,
      scene: Cakewar_Game,
      physics: {
        default: 'arcade',
        arcade: {
          debug: false,
          gravity: { y: 0 }
        }
      }
    };
    //console.log(store.getState());
    if (store.getState().game.phaser == null)
      setPhasergame(new Phaser.Game(config));

  })
  return (

    <div className="Game">

      <header className="Game-header">



      </header>
    </div>
  );
}



class Cakewar_Game extends Phaser.Scene {
  constructor() {
    console.log("Construct");
    super("PlayGame");
  }


  preload() {


    this.load.image('sky', 'img/sky0.png');
    this.load.spritesheet('player', 'img/player-small-rect.png',
      { frameWidth: 35, frameHeight: 35 });

    this.load.spritesheet('ennemy10', 'img/ennemy-small-rect10.png',
      { frameWidth: 35, frameHeight: 35 });
    this.load.spritesheet('ennemy9', 'img/ennemy-small-rect9.png',
      { frameWidth: 35, frameHeight: 35 });
    this.load.spritesheet('ennemy8', 'img/ennemy-small-rect8.png',
      { frameWidth: 35, frameHeight: 35 });
    this.load.spritesheet('ennemy7', 'img/ennemy-small-rect7.png',
      { frameWidth: 35, frameHeight: 35 });
    this.load.spritesheet('ennemy6', 'img/ennemy-small-rect6.png',
      { frameWidth: 35, frameHeight: 35 });
    this.load.spritesheet('ennemy6', 'img/ennemy-small-rect6.png',
      { frameWidth: 35, frameHeight: 35 });
    this.load.spritesheet('ennemy5', 'img/ennemy-small-rect5.png',
      { frameWidth: 35, frameHeight: 35 });
    this.load.spritesheet('ennemy4', 'img/ennemy-small-rect4.png',
      { frameWidth: 35, frameHeight: 35 });
    this.load.spritesheet('ennemy3', 'img/ennemy-small-rect3.png',
      { frameWidth: 35, frameHeight: 35 });
    this.load.spritesheet('ennemy2', 'img/ennemy-small-rect2.png',
      { frameWidth: 35, frameHeight: 35 });
    this.load.spritesheet('ennemy1', 'img/ennemy-small-rect1.png',
      { frameWidth: 35, frameHeight: 35 });

    this.load.spritesheet('ennemy', 'img/ennemy-small-rect.png',
      { frameWidth: 35, frameHeight: 35 });



    this.load.spritesheet('smartie', 'img/smartie-small.png',
      { frameWidth: 16, frameHeight: 16 });


    this.load.image('ground', 'img/metal.jpg');

    this.load.spritesheet('medikit', 'img/donut-small.png',
      { frameWidth: 50, frameHeight: 50 });




  }


  create() {
    console.log("Create");
    this.shot_timer = 0;
    this.acceleration = 0.1;
    console.log("create");
    this.rotation = 0;
    this.speed = 0;
    this.score=0;
    this.sprites = [];
    this.add.image(575, 300, 'sky');
    this.healthbar = new HealthBar(this, 10, 10);

    this.text = this.add.text(1000, 10, "kCal: "+this.score, { font: "20px Arial", fill: "#ff0044", align: "center" });
    /*
    this.platforms = this.physics.add.staticGroup();

    this.platforms.create(400, 568, 'ground').setScale(2).refreshBody();

    this.platforms.create(600, 400, 'ground');
    this.platforms.create(50, 250, 'ground');
    this.platforms.create(750, 220, 'ground');
    */
    this.add.tileSprite(100, 300, 20, 400, 'ground');
    this.add.tileSprite(1020, 300, 20, 400, 'ground');
    this.add.tileSprite(560, 108, 700, 20, 'ground');
    this.add.tileSprite(560, 492, 700, 20, 'ground');

    this.add.tileSprite(560, 300, 20, 200, 'ground');




    openSocket();
    //join the game
    joinGame(() => {

      // Update sprites if there are other players
      // Bodies are static by default. Static bodies will never move.

      store.getState().game.sprites.forEach((sprite) => {

        if (sprite != null) {
          console.log(sprite);
          //  const r1 = this.add.polygon(sprite.posX, sprite.posY, [0, 32, 32, 32, 16, 0], 0x6666ff);
          if (sprite.type === CAKE_SHIP) {
            if (sprite.id !== store.getState().game.id) {
              let newSprite = this.physics.add.sprite(sprite.posX, sprite.posY, 'ennemy10');
              newSprite.name = sprite.id;
              this.sprites.push(newSprite);
            }
            else {


              let newPlayer = this.physics.add.sprite(sprite.posX, sprite.posY, 'player');
              newPlayer.name = sprite.id;
              this.player = newPlayer;
            }



          }

        }
      }
      );


      //const player = this.add.triangle(20, 20, 50, 148, 98, 148, 74, 50, 0x6666ff);
      //waiting for other players to join
      waitOnDisconnect
        ((player) => {

          //if (player.id != store.getState().game.id)

          let element = this.sprites.find(el => el.name === player.id);
          if (element != null) {
            element.destroy();
            let index = this.sprites.findIndex(el => el.name === player.id);
            this.sprites.splice(index, 1);
          }

        });
      waitForOtherPlayers((player) => {

        if (player.id !== store.getState().game.id) {
          let newSprite = this.physics.add.sprite(player.posX, player.posY, 'ennemy10');
          newSprite.name = player.id;
          this.sprites.push(newSprite);
        }

        // upDateSpawnSprites(this.sprites, this.player, this.physics);
      });
      listenToStateChange((payload) => {
        if (payload.state === WON_GAME)
          window.location.href = '/won';
        if (payload.state === LOST_GAME)
          window.location.href = '/lost';

      });


      this.player.setBounce(0.2);
      this.player.setCollideWorldBounds(false);
      /*
        this.anims.create({
          key: 'left',
          frames: this.anims.generateFrameNumbers('player', { start: 0, end: 3 }),
          frameRate: 10,
          repeat: -1
        });
      
        this.anims.create({
          key: 'turn',
          frames: [{ key: 'player', frame: 4 }],
          frameRate: 20
        });
      
        this.anims.create({
          key: 'right',
          frames: this.anims.generateFrameNumbers('player', { start: 5, end: 8 }),
          frameRate: 10,
          repeat: -1
        });
        */

    }
    );

  }

  upDateAllSprites() {
    //destroy all sprites
    if (this.sprites != null)
      this.sprites.forEach((sprite) => {

        sprite.destroy();


      })
    //recreate sprites
    if (store.getState().game.sprites != null)
      store.getState().game.sprites.forEach((sprite) => {

        if (sprite) {
          if (sprite.id === store.getState().game.id) {
            if (!this.player) {

              this.player = this.physics.add.sprite(sprite.posX, sprite.posY, 'player');
              this.player.name = sprite.id;
            }
            else {
              this.text.setText("kCal: "+sprite.score);
    
              this.player.x = sprite.posX;
              this.player.y = sprite.posY;
              this.player.setRotation(sprite.rotation)
            }

          }
          else
            if (sprite.type === CAKE_SHIP) {
              var newsprite;
              if (sprite.lifePoints == 200) {
                newsprite = this.physics.add.sprite(sprite.posX, sprite.posY, 'ennemy10');
              }
              else if (sprite.lifePoints >= 180) {
                newsprite = this.physics.add.sprite(sprite.posX, sprite.posY, 'ennemy9');
              }
              else if (sprite.lifePoints >= 160) {
                newsprite = this.physics.add.sprite(sprite.posX, sprite.posY, 'ennemy8');
              }
              else if (sprite.lifePoints >= 160) {
                newsprite = this.physics.add.sprite(sprite.posX, sprite.posY, 'ennemy7');
              }
              else if (sprite.lifePoints >= 140) {
                newsprite = this.physics.add.sprite(sprite.posX, sprite.posY, 'ennemy6');
              }
              else if (sprite.lifePoints >= 120) {
                newsprite = this.physics.add.sprite(sprite.posX, sprite.posY, 'ennemy5');
              }
              else if (sprite.lifePoints >= 100) {
                newsprite = this.physics.add.sprite(sprite.posX, sprite.posY, 'ennemy4');
              }
              else if (sprite.lifePoints >= 80) {
                newsprite = this.physics.add.sprite(sprite.posX, sprite.posY, 'ennemy3');
              }
              else if (sprite.lifePoints >= 60) {
                newsprite = this.physics.add.sprite(sprite.posX, sprite.posY, 'ennemy2');
              }
              else if (sprite.lifePoints >= 40) {
                newsprite = this.physics.add.sprite(sprite.posX, sprite.posY, 'ennemy1');
              }


              else
                newsprite = this.physics.add.sprite(sprite.posX, sprite.posY, 'ennemy');

              newsprite.name = sprite.id;
              newsprite.setRotation(sprite.rotation);
              this.sprites.push(newsprite);



            }
          if (sprite.type === SMARTIE_SHOT) {


            let newsprite = this.physics.add.sprite(sprite.posX, sprite.posY, 'smartie')
            newsprite.name = sprite.id;
            this.sprites.push(newsprite);

          }
          if (sprite.type === MEDI_KIT) {

            let newsprite = this.physics.add.sprite(sprite.posX, sprite.posY, 'medikit')
            newsprite.name = sprite.id;
            this.sprites.push(newsprite);

          }





        }
      }
      )
  }
  getSpriteById(id) {
    let index = this.sprites.findIndex(sprite => sprite.name === id);
    if (index === -1)
      return null;
    else return (this.sprites[index]);


  }

  update() {
    var keyboard = { left: false, right: false, up: false, down: false, space: false };

    if (store.getState().game.running) {

      let cursors = this.input.keyboard.createCursorKeys();


      //speed and stop
      if (cursors.space.isDown) {
        keyboard.space = true;

      }
      if (cursors.up.isDown || this.input.keyboard.addKey('W').isDown) {
        keyboard.up = true;

      }
      else if
        (cursors.down.isDown || this.input.keyboard.addKey('S').isDown) {
        keyboard.down = true;

      }


      //rotation
      if (!((cursors.left.isDown && cursors.right.isDown) || (this.input.keyboard.addKey('A').isDown && this.input.keyboard.addKey('D').isDown)))
        if (cursors.left.isDown || this.input.keyboard.addKey('A').isDown) {
          keyboard.left = true;
        }
        else if (cursors.right.isDown || this.input.keyboard.addKey('D').isDown) {
          keyboard.right = true;

        }

      updatePlayerPosition({
        id: store.getState().game.id,
        speed: this.speed,
        rotation: this.rotation,
        keyboard: keyboard
      });

      this.upDateAllSprites();
    }
    //update lifebar

    this.healthbar.setValue(store.getState().game.lifePoints);


  }

}

class HealthBar {

  constructor(scene, x, y) {
    this.bar = new Phaser.GameObjects.Graphics(scene);

    this.x = x;
    this.y = y;
    this.value = 200;
    this.p = 76 / 100;
    this.draw();

    scene.add.existing(this.bar);

  }
  setValue(amount) {
    this.value = amount;

    if (this.value < 0) {
      this.value = 0;
    }

    this.draw();

    return (this.value === 0);
  }

  decrease(amount) {
    this.value -= amount;

    if (this.value < 0) {
      this.value = 0;
    }

    this.draw();

    return (this.value === 0);
  }

  draw() {
    this.bar.clear();

    //  BG
    this.bar.fillStyle(0x000000);
    this.bar.fillRect(this.x, this.y, 160, 16);

    //  Health

    this.bar.fillStyle(0xffffff);
    this.bar.fillRect(this.x + 2, this.y + 2, 152, 12);

    if (this.value < 30) {
      this.bar.fillStyle(0xff0000);
    }
    else {
      this.bar.fillStyle(0x00ff00);
    }

    var d = Math.floor(this.p * this.value);

    this.bar.fillRect(this.x + 2, this.y + 2, d, 12);
  }

}

export default Game;