import logo from '../img/logo.png';
import title from '../img/title.png';
import '../css/Start.css';
import { Link } from 'react-router-dom'



function Start() {
  return (
    
    <div className="Start">
      
      <header className="App-header">
        <img src={title} className="App-title" alt="logo" />
        {/*
        <img src={logo} className="App-logo" alt="logo" />
  */}
      
        <Link
         
         to="/game"
       
    
        >
          Start
        </Link>
  
      </header>
    </div>
  );
}

export default Start;
