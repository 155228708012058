
import '../css/Lost.css';
import { Link } from 'react-router-dom'

function Lost() {
  return (
    
    <div className="Lost">
   
      <header className="Lost-header">
       <h1>Du hast leider verloren!</h1>

       <Link to="/game">Nochmal</Link>
       
       </header>
    </div>
  );
}

export default Lost;