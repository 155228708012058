
import React, { useEffect } from 'react';
import '../css/Lobby.css';
import Phaser from 'phaser';


import {startGame } from '../actions/gameActions'
import store from '../store';

import { CAKE_SHIP, SMARTIE_SHOT } from './types';

const HEIGHT = 600;
const WIDTH = 1125;






export const Admin = () => {
  
  return (

    <div className="Game">

      <header className="Game-header">
        <button onClick={startGame}>
          Start
        </button>



      </header>
    </div>
  );

}
